import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));
        // alert(user);
        // if (user && user.token) {
        if (user) {
          next({ name: "default" });
        } else {
          next();
        }

      },
    },
  },
   {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout.vue"),
    meta: {
      title: "logout",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
       // let user = JSON.parse(localStorage.getItem('user'));
        // alert(user);
        // if (user && user.token) {
         next();
        // if (user) {
        //   next({ name: "default" });
        // } else {
         
        // }

      },
    },
  },

  // {
  //   path: "/logout",
  //   name: "logout",
  //   meta: {
  //     title: "Logout", authRequired: false,
  //     // beforeResolve(routeTo, routeFrom, next) {
  //     //   if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  //     //     store.dispatch("auth/logOut");
  //     //   } else {
  //     //     store.dispatch("authfack/logout");
  //     //   }
  //     //   const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
  //     //     route.push("/login")
  //     //   );
  //     //   // Navigate back to previous page, or home as a fallback
  //     //   next(
  //     //     authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
  //     //   );
  //     // },
  //   },
  //   component: () => import("../views/account/login")
  // },
  {
    path: "/signin/:okmsg",
    name: "signin",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/report/plreport/:from_date/:to_date",
    name: "PLReport",
    component: () => import("../views/reports/expenses/plreport"),
    meta: {
      title: "Profit & Loss Report",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/verifyregister/:id",
    name: "verifyregister",
    component: () => import("../views/account/verifyregister.vue"),
    meta: {
      title: "verifyregister",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));
        //alert("at dashboard = "+user);
        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }

      },
    },
    component: () => import("../views/dashboard/crm/index.vue"),
  }, {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/index.vue"),
  },{
    path: "/categories/Expenses-Head",
    name: "Expenses Head",
    meta: {
      title: "Expenses Head",
      reload: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/categories.vue"),
  },
  // {
  //   path: "/categories/Deal-By-Expert",
  //   name: "Deal By Expert",
  //   meta: {
  //     title: "Deal By Expert",
  //     reload: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       let user = JSON.parse(localStorage.getItem('user'));

  //       // if (user && user.token) {
  //       if (user === null) {
  //         next({ name: "login" });
  //       } else {
  //         next();
  //       }
  //     },
  //   },
  //   component: () => import("../views/dashboard/crm/categories.vue"),
  // },
  // {
  //   path: "/categories/University",
  //   name: "University",
  //   meta: {
  //     title: "University",
  //     reload: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       let user = JSON.parse(localStorage.getItem('user'));

  //       // if (user && user.token) {
  //       if (user === null) {
  //         next({ name: "login" });
  //       } else {
  //         next();
  //       }
  //     },
  //   },
  //   component: () => import("../views/dashboard/crm/categories.vue"),
  // },
  {
    path: "/categories/Work-Type",
    name: "Work Type",
    meta: {
      title: "Work Type",
      reload: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/categories.vue"),
  },{
    path: "/categories/Payment-Holder-Name",
    name: "Payment Holder Name",
    meta: {
      title: "Payment Holder Name",
      reload: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/categories.vue"),
  },{
    path: "/payments/payment_accounts",
    name: "Payment Accounts",
    meta: {
      title: "Payment Accounts",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/payment_accounts.vue"),
  },{
    path: "/expenses/manage",
    name: "Manage Expenses",
    meta: {
      title: "Manage Expenses",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/expenses.vue"),
  },{
    path: "/users",
    name: "Users",
    meta: {
      title: "Users",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/users.vue"),
  },{
    path: "/profiles",
    name: "Profiles",
    meta: {
      title: "Profiles",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/profiles.vue"),
  },{
    path: "/projects",
    name: "Projects",
    meta: {
      title: "Projects",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/dashboard/crm/projects.vue"),
  },{
    path: "/pages/profile-setting",
    name: "Setting",
    meta:
    {
      title: "Setting",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let user = JSON.parse(localStorage.getItem('user'));

        // if (user && user.token) {
        if (user === null) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../views/pages/profile/setting"),

   }
   //,{
  //   path: "/reports/expenses/list",
  //   name: "Expenses List",
  //   meta:
  //   {
  //     title: "Expenses List",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       let user = JSON.parse(localStorage.getItem('user'));

  //       // if (user && user.token) {
  //       if (user === null) {
  //         next({ name: "login" });
  //       } else {
  //         next();
  //       }
  //     },
  //   },
  //   component: () => import("../views/reports/expenses/expenses-list"),

  // }
];